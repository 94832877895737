import React from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  CardImg,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import avatar1 from "../../assets/Group.svg"

//i18n
import { withTranslation } from "react-i18next"
import dataContext from "context/data/dataContext"
const CreateUser = props => {
  const { signupUser } = React.useContext(dataContext)
  //meta title
  document.title = "Dashboard | Skote - React Admin & Dashboard Template"
  const [details, setdetails] = React.useState({
    name: "",
    email: "",
    phone: "",
    password: "",
  })
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Create User")}
          />
          <Row>
            <Col sm={12}>
              <Card>
                <CardBody className="">
                  <CardTitle className="text-uppercase mb-0"></CardTitle>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <div className="d-flex flex-wrap">
                    <Col md="4" className="align-self-center ">
                      <CardImg
                        top
                        className="img-fluid w-50 mx-auto"
                        src={avatar1}
                        alt="Skote"
                      />
                    </Col>
                    <Col md="6">
                      <CardTitle className="h5 my-2">User Details</CardTitle>

                      <Form
                        className=" mt-2"
                        onSubmit={async e => {
                          e.preventDefault()
                          ;(await signupUser(details))
                            ? alert("done")
                            : alert("error")
                        }}
                      >
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            id="floatingnameInput"
                            placeholder="Enter Name"
                            name="name"
                            value={details?.name}
                            onChange={e => {
                              setdetails({
                                ...details,
                                [e.target.name]: e.target.value,
                              })
                            }}
                          />
                          <label htmlFor="floatingnameInput">Name</label>
                        </div>

                        <Row>
                          <Col md={6}>
                            <div className="form-floating mb-3">
                              <input
                                type="email"
                                className="form-control"
                                id="floatingemailInput"
                                placeholder="Enter Email address"
                                name="email"
                                value={details?.email}
                                onChange={e => {
                                  setdetails({
                                    ...details,
                                    [e.target.name]: e.target.value,
                                  })
                                }}
                              />
                              <label htmlFor="floatingemailInput">
                                Email address
                              </label>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="floatingemailInput"
                                placeholder="Enter Phone Number"
                                name="phone"
                                value={details?.phone}
                                onChange={e => {
                                  setdetails({
                                    ...details,
                                    [e.target.name]: e.target.value,
                                  })
                                }}
                              />
                              <label htmlFor="floatingemailInput">
                                Phone Number
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <div className="form-floating mb-3">
                          <input
                            type="password"
                            className="form-control"
                            id="password"
                            placeholder="Enter password"
                            name="password"
                            value={details?.password}
                            onChange={e => {
                              setdetails({
                                ...details,
                                [e.target.name]: e.target.value,
                              })
                            }}
                          />
                          <label htmlFor="password">Password</label>
                        </div>

                        <div>
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                          >
                            Register
                          </button>
                        </div>
                      </Form>
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(CreateUser)
