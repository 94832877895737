import React from "react"
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledTooltip,
  Form,
  Row,
  Col,
} from "reactstrap"
import { Watch, ThreeDots, ColorRing } from "react-loader-spinner"
import axios from "axios"
import dataContext from "context/data/dataContext"

const CardDetails = ({ data, index }) => {
  const {
    masterCategoryList,
    GetMasterCategoryList,
    DeleteMasterCategoryList,
  } = React.useContext(dataContext)

  const [loading, setloading] = React.useState(false)
  const [primaryCatagoryList, setPrimaryCatagoryList] = React.useState([])

  const [categoryTypeList, setcategoryTypeList] = React.useState([])
  const [showHidePrimary, setShowHidePrimary] = React.useState(true)
  const [details, setdetails] = React.useState({
    _id: data._id,
    category_for: data.category_for,
    category_type: data.category_type,
    category_value: data.category_value,
    sub_category_type: data?.sub_category_type,
  })

  const [modal, setModal] = React.useState(false)
  const toggle = () => {
    setModal(!modal)
    setdetails({
      _id: data._id,
      category_for: data.category_for,
      category_type: data.category_type,
      category_value: data.category_value,
      sub_category_type: data?.sub_category_type,
    })
  }
  const GetPrimaryCatagory = async (
    categoryFor,
    categorType = "Primary Category"
  ) => {
    if (masterCategoryList?.status) {
      let newlist = []
      if (masterCategoryList.list.length > 0) {
        masterCategoryList.list.forEach(element => {
          if (
            categoryFor === element.category_for &&
            categorType === element.category_type
          ) {
            newlist.push(element.category_value)
          }
        })
        newlist.length > 0 && setPrimaryCatagoryList(newlist)
        newlist.length == 0 &&
          setPrimaryCatagoryList(["No Primary Catagory Found"])
      } else {
        setPrimaryCatagoryList(["No Primary Catagory Found"])
      }
    } else {
      setPrimaryCatagoryList(["some error found"])
    }
  }
  const FindAndSetCategoryTypeList = value => {
    if (value == "Master Packing List") {
      setcategoryTypeList(["Primary Category", "Secondry Category"])
    } else if (value == "Planing List") {
      setcategoryTypeList(["Primary Category", "Secondry Category", "Timeline"])
    } else if (value == "Catalog Product") {
      setcategoryTypeList(["Primary Category", "Secondry Category"])
    } else if (value == "Catalog Service") {
      setcategoryTypeList(["Primary Category", "Secondry Category"])
    } else if (value == "Planing/Preparation") {
      setcategoryTypeList([
        "Primary Category",
        "Secondry Category",
        "Theme",
        "Variable",
      ])
    } else {
      setcategoryTypeList(["On Development"])
    }
  }
  const FindAndSetSecondryCategoryList = value => {
    console.log(value)

    if (value == "Secondry Category") {
      setShowHidePrimary(false)
    } else {
      setShowHidePrimary(true)
    }
  }

  const UpdateCategoryList = async () => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_HOST}/categories/update_category_list`,
          details,
          {
            headers: {
              Authorization: localStorage.getItem("authUser"),
            },
          }
        )
        .then(response => {
          var result = response
          console.log("result", result)
          GetMasterCategoryList()

          setloading(false)
          toggle()
        })
        .catch(error => {
          console.log(error)
          alert("error")
        })
    } catch (error) {
      console.log(error)
      alert("error")
    }
  }

  React.useEffect(() => {
    FindAndSetCategoryTypeList(data.category_for)
    GetPrimaryCatagory(data.category_for)
    FindAndSetSecondryCategoryList(data.category_type)
  }, [data])

  return (
    <React.Fragment>
      <Modal
        isOpen={modal}
        fade={false}
        toggle={toggle}
        className="top-50 left-50 mx-auto"
        style={{ transform: "translateY(-50%)" }}
      >
        <ModalHeader toggle={toggle}>Update Master Category List</ModalHeader>

        <ModalBody>
          <Form
            className=" mt-2"
            onSubmit={async e => {
              e.preventDefault()
              setloading(true)
              console.log("details :>> ", details)
              let status = await UpdateCategoryList(details)
              status && alert("done")
              status && toggle()
              status &&
                //   !status && alert("error")
                setloading(false)
            }}
          >
            <Row>
              <Col md={6}>
                <div className="form-floating mb-3">
                  <select
                    value={details?.category_for}
                    onChange={e => {
                      setdetails({
                        ...details,
                        ["category_for"]: e.target.value,
                        ["category_type"]: "",
                      })
                      FindAndSetCategoryTypeList(e.target.value)
                      GetPrimaryCatagory(e.target.value)
                      setShowHidePrimary(true)
                    }}
                    className="form-select"
                  >
                    <option value="">Choose..</option>

                    <option value="Master Packing List">
                      Master Packing List
                    </option>
                    <option value="Planing List">Planing List</option>
                    <option value="Catalog Product">Catalog Product</option>
                    <option value="Catalog Services">Catalog Services</option>
                  </select>
                  <label htmlFor="floatingSelectGrid">Category For</label>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-floating mb-3">
                  <select
                    value={details?.category_type}
                    onChange={e => {
                      e.target.value != "Secondry Category" &&
                        setdetails({
                          ...details,
                          ["category_type"]: e.target.value,
                          ["sub_category_type"]: "",
                        })
                      e.target.value == "Secondry Category" &&
                        setdetails({
                          ...details,
                          ["category_type"]: e.target.value,
                          ["sub_category_type"]: data.sub_category_type,
                        })
                      FindAndSetSecondryCategoryList(e.target.value)
                    }}
                    className="form-select"
                  >
                    {categoryTypeList[0] && <option value="">Choose..</option>}
                    {categoryTypeList[0] ? (
                      categoryTypeList.map((data, i) => {
                        return (
                          <option key={i} value={data}>
                            {data}
                          </option>
                        )
                      })
                    ) : (
                      <option value="">Choose Category For First..</option>
                    )}
                  </select>
                  <label htmlFor="floatingSelectGrid">Category Type</label>
                </div>
              </Col>
            </Row>
            <div
              className={`${showHidePrimary && "d-none"} form-floating mb-3`}
            >
              <select
                value={details?.sub_category_type}
                onChange={e => {
                  setdetails({
                    ...details,
                    ["sub_category_type"]: e.target.value,
                  })
                }}
                className="form-select"
              >
                {primaryCatagoryList[0] && <option value="">Choose..</option>}
                {primaryCatagoryList.map((data, i) => {
                  return (
                    <option key={i} value={data}>
                      {data}
                    </option>
                  )
                })}
              </select>
              <label htmlFor="floatingSelectGrid">
                Select Dependent Primary Category
              </label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingnameInput"
                placeholder="Enter Category Value"
                name="category_value"
                value={details?.category_value}
                onChange={e => {
                  setdetails({
                    ...details,
                    [e.target.name]: e.target.value,
                  })
                }}
              />
              <label htmlFor="floatingnameInput">Category Value</label>
            </div>

            <div className="d-flex justify-content-end gap-2">
              <Button
                onClick={toggle}
                className="btn btn-success mx-2 bg-gradient py-1"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="btn btn-success mx-2 bg-gradient py-1"
              >
                {!loading ? (
                  "Submit"
                ) : (
                  <ColorRing
                    visible={true}
                    height="15"
                    width="15"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                  />
                )}
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
      <tr>
        <td>{index + 1}</td>
        <td>{data.category_for}</td>
        <td className="text-center">
          {data.category_type ? data.category_type : "-"}
        </td>
        <td className="text-center">
          {data?.sub_category_type ? data.sub_category_type + " // " : ""}
          {data.category_value ? data.category_value : "-"}
        </td>

        <td className="d-flex gap-2 justify-content-center">
          <i
            className="bx bx-edit-alt"
            onClick={toggle}
            style={{ cursor: "pointer" }}
          ></i>
          <i
            className="bx bx-trash"
            onClick={() => DeleteMasterCategoryList({ list_id: data._id })}
            style={{ cursor: "pointer" }}
          ></i>
        </td>
      </tr>
    </React.Fragment>
  )
}

export default CardDetails
