import PropTypes from "prop-types"
import React from "react"
import { useSelector } from "react-redux"
import { Routes, Route } from "react-router-dom"
import { layoutTypes } from "./constants/layout"
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"
import { Navigate } from "react-router-dom"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/VerticalLayout/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"

const getLayout = layoutType => {
  let Layout = VerticalLayout
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout
      break
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout
      break
    default:
      break
  }
  return Layout
}

const list1 = [
  {
    routeNames: [
      "Dashboard",
      "Profile",
      "Page Access",
      "Create Admin",
      "Create User",
      "User List",
      "Admin List",
      "Master Packing list",
      "Planing list",
      "Catalog Product",
      "Catalog Services",
      "Master Category",
      "Planing And Preparation",
    ],
    type: "Super-Admin",
  },
  {
    routeNames: ["Dashboard", "Profile", "User List", "Create User"],
    type: "Admin",
  },
]
import dataContext from "context/data/dataContext"
const App = () => {
  const { setRoutersState, reload } = React.useContext(dataContext)
  const { layoutType } = useSelector(state => ({
    layoutType: state.Layout.layoutType,
  }))

  const Layout = getLayout(layoutType)

  React.useEffect(() => {
    list1.forEach(element => {
      element.type == localStorage.getItem("Type") &&
        setRoutersState(element.routeNames)
    })
  }, [reload])

  return (
    <React.Fragment>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
            exact={true}
          />
        ))}

        {authProtectedRoutes
          .filter(route => {
            if (
              list1.filter(
                list =>
                  list.type == localStorage.getItem("Type") &&
                  list.routeNames.includes(route.name)
              ).length > 0
            ) {
              return route
            } else {
              return {
                path: "/",
                exact: true,
                component: <Navigate to="/dashboard" />,
                name: "Dashboard",
              }
            }
          })
          .map((route, idx) => (
            <Route
              path={route.path}
              element={
                <Authmiddleware>
                  <Layout>{route.component}</Layout>
                </Authmiddleware>
              }
              key={idx}
              exact={true}
            />
          ))}
      </Routes>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

export default App
