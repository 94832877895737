import React from "react"
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledTooltip,
  Form,
  Row,
  Col,
} from "reactstrap"
import { Watch, ThreeDots, ColorRing } from "react-loader-spinner"
import axios from "axios"
const UserDetails = ({ data, index }) => {
  const [loading, setloading] = React.useState(false)
  const [details, setdetails] = React.useState({
    _id: data._id,
    name: data.name,
    email: data.email,
    phone: data.phone,
    status: data.status,
  })

  const [modal, setModal] = React.useState(false)
  const toggle = () => {
    setModal(!modal)
    setdetails({
      name: data.name,
      email: data.email,
      phone: data.phone,
      status: data.status,
    })
  }
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]

  const UpdateUser = async () => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_HOST}/admin/updateuser/${data._id}`,
          details,
          {
            headers: {
              Authorization: localStorage.getItem("authUser"),
            },
          }
        )
        .then(response => {
          var result = response
          console.log("result", result)
          // if (result.status == 200 && result?.data?.users.length > 0) {
          //   // setUserList({ status: true, list: result.data.users })
          // } else {
          //   // setUserList({ status: false, message: "No Data Found" })
          // }
        })
        .catch(error => {
          console.log(error)
          // setUserList({ status: false, message: "Internal Server Error" })
        })
    } catch (error) {
      console.log(error)

      // setUserList({ status: false, message: "Internal Server Error" })
    }
  }
  return (
    <React.Fragment>
      <Modal
        isOpen={modal}
        fade={false}
        toggle={toggle}
        className="top-50 left-50 mx-auto"
        style={{ transform: "translateY(-50%)" }}
      >
        <ModalHeader toggle={toggle}>Update User</ModalHeader>

        <ModalBody>
          <Form
            className=" mt-2"
            onSubmit={async e => {
              e.preventDefault()
              toggle()
              ;(await UpdateUser()) ? alert("done") : alert("error")
            }}
          >
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingnameInput"
                placeholder="Enter Name"
                name="name"
                value={details?.name}
                onChange={e => {
                  setdetails({
                    ...details,
                    [e.target.name]: e.target.value,
                  })
                }}
              />
              <label htmlFor="floatingnameInput">Name</label>
            </div>

            <Row>
              <Col md={6}>
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingemailInput"
                    placeholder="Enter Email address"
                    name="email"
                    value={details?.email}
                    onChange={e => {
                      setdetails({
                        ...details,
                        [e.target.name]: e.target.value,
                      })
                    }}
                  />
                  <label htmlFor="floatingemailInput">Email address</label>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingemailInput"
                    placeholder="Enter Phone Number"
                    name="phone"
                    value={details?.phone}
                    onChange={e => {
                      setdetails({
                        ...details,
                        [e.target.name]: e.target.value,
                      })
                    }}
                  />
                  <label htmlFor="floatingemailInput">Phone Number</label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="form-floating mb-3">
                  <select
                    value={details.status}
                    onChange={e => {
                      setdetails({
                        ...details,
                        ["status"]: e.target.value,
                      })
                    }}
                    className="form-select"
                  >
                    <option value="Churned">Churned</option>
                    <option value="Registered" className="d-none">
                      Registered
                    </option>
                    <option value="Inactive" className="d-none">
                      Inactive
                    </option>
                    <option value="Active" className="d-none">
                      Active
                    </option>
                  </select>
                  <label htmlFor="floatingSelectGrid">Status</label>
                </div>
              </Col>
            </Row>

            <div className="d-flex justify-content-end gap-2">
              <Button
                onClick={toggle}
                className="btn btn-success mx-2 bg-gradient py-1"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="btn btn-success mx-2 bg-gradient py-1"
              >
                {!loading ? (
                  "Submit"
                ) : (
                  <ColorRing
                    visible={true}
                    height="15"
                    width="15"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                  />
                )}
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
      <tr>
        <td>{index + 1}</td>
        <td>{data.name}</td>
        <td className="text-center">{data.email}</td>
        <td className="text-center">{data.phone}</td>
        <td className="text-center">
          {data.status == "Inactive" && (
            <span className="bg-warning bg-gradient text-white px-2 py-1 font-size-11 rounded-pill">
              {data.status}
            </span>
          )}
          {data.status == "Registered" && (
            <span className="bg-primary bg-gradient text-white px-2 py-1 font-size-11 rounded-pill">
              {data.status}
            </span>
          )}
          {data.status == "Active" && (
            <span className="bg-success bg-gradient text-white px-2 py-1 font-size-11 rounded-pill">
              {data.status}
            </span>
          )}
          {data.status == "Churned" && (
            <span className="bg-danger bg-gradient text-white px-2 py-1 font-size-11 rounded-pill">
              {data.status}
            </span>
          )}
        </td>
        <td className="text-center">
          {new Date(data.registerDate).getDate() < 10
            ? "0" + new Date(data.registerDate).getDate()
            : new Date(data.registerDate).getDate()}
          {"-"}
          {month[new Date(data.registerDate).getMonth()]}
          {"-"}
          {new Date(data.registerDate).getFullYear()}{" "}
          {new Date(data.registerDate).getHours() < 10
            ? "0" + new Date(data.registerDate).getHours()
            : new Date(data.registerDate).getHours()}
          :
          {new Date(data.registerDate).getMinutes() < 10
            ? "0" + new Date(data.registerDate).getMinutes()
            : new Date(data.registerDate).getMinutes()}
          :
          {new Date(data.registerDate).getSeconds() < 10
            ? "0" + new Date(data.registerDate).getSeconds()
            : new Date(data.registerDate).getSeconds()}
        </td>
        <td className="text-center">
          <i
            className="bx bx-edit-alt"
            onClick={toggle}
            style={{ cursor: "pointer" }}
          ></i>
        </td>
      </tr>
    </React.Fragment>
  )
}

export default UserDetails
