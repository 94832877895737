import React from "react"
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledTooltip,
  Form,
  Row,
  Col,
  Input,
  Label,
} from "reactstrap"
import { Watch, ThreeDots, ColorRing } from "react-loader-spinner"
import axios from "axios"
import dataContext from "context/data/dataContext"

const CardDetails = ({
  data,
  index,
  primaryCatagoryList,
  secondryCatagory,
  themeCategoryList,
  variableCategoryList,
}) => {
  const { GetPlaningAndPreparationList, DeletePlaningAndPreparationList } =
    React.useContext(dataContext)
  const [secondryCatagoryList, setSecondryCatagoryList] = React.useState([])

  const [loading, setloading] = React.useState(false)
  const [details, setdetails] = React.useState(data)

  const [modal, setModal] = React.useState(false)
  const toggle = () => {
    setModal(!modal)
    setdetails(data)
  }
  const FindAndSetSecondaryCategory = value => {
    let newList = []
    if (secondryCatagory[0]) {
      secondryCatagory.forEach(item => {
        if (value === item.sub_category_type) {
          newList.push(item.category_value)
        }
      })
      if (newList.length == 0) {
        setSecondryCatagoryList(["no secondary catagory found"])
      } else {
        setSecondryCatagoryList(newList)
      }
    } else {
      setSecondryCatagoryList(["no secondary catagory found"])
    }
  }
  const UpdateUser = async () => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_HOST}/categories/update_planing_preparation_list`,
          details,
          {
            headers: {
              Authorization: localStorage.getItem("authUser"),
            },
          }
        )
        .then(response => {
          var result = response
          console.log("result", result)
          GetPlaningAndPreparationList()

          setloading(false)
          toggle()
        })
        .catch(error => {
          console.log(error)
          alert("error")
        })
    } catch (error) {
      console.log(error)
      alert("error")
    }
  }
  React.useEffect(() => {
    FindAndSetSecondaryCategory(data.primary_category)
  }, [data])
  return (
    <React.Fragment>
      <Modal
        isOpen={modal}
        fade={false}
        toggle={toggle}
        className="top-50 left-50 mx-auto"
        style={{ transform: "translateY(-50%)" }}
      >
        <ModalHeader toggle={toggle}>
          Update Planing/Preparation List
        </ModalHeader>

        <ModalBody>
          <Form
            className=" mt-2"
            onSubmit={async e => {
              e.preventDefault()
              setloading(true)
              UpdateUser(details)
            }}
          >
            <Row>
              <Col sm={4}>
                <div className="mb-3">
                  <div className="form-check ms-1">
                    <Input
                      type="checkbox"
                      className="form-check-Input"
                      id="formrow-customCheck1"
                      checked={details?.limited}
                      onChange={e => {
                        setdetails({
                          ...details,
                          ["limited"]: e.target.checked,
                        })
                      }}
                    />
                    <Label
                      className="form-check-Label"
                      htmlFor="formrow-customCheck1"
                    >
                      Limited
                    </Label>
                  </div>
                </div>{" "}
              </Col>
              <Col sm={4}>
                <div className="mb-3   ">
                  <div
                    className="form-check  mx-auto "
                    style={{ width: "fit-content" }}
                  >
                    <Input
                      type="checkbox"
                      className="form-check-Input"
                      id="formrow-customCheck2"
                      checked={details?.premium}
                      onChange={e => {
                        setdetails({
                          ...details,
                          ["premium"]: e.target.checked,
                        })
                      }}
                    />
                    <Label
                      className="form-check-Label"
                      htmlFor="formrow-customCheck2"
                    >
                      Premium
                    </Label>
                  </div>
                </div>{" "}
              </Col>
              <Col sm={4}>
                <div className="mb-3">
                  <div className="form-check float-end me-1">
                    <Input
                      type="checkbox"
                      className="form-check-Input"
                      id="formrow-customCheck3"
                      checked={details?.enterprise}
                      onChange={e => {
                        setdetails({
                          ...details,
                          ["enterprise"]: e.target.checked,
                        })
                      }}
                    />
                    <Label
                      className="form-check-Label"
                      htmlFor="formrow-customCheck3"
                    >
                      Enterprise
                    </Label>
                  </div>
                </div>{" "}
              </Col>
            </Row>
            <div className="form-floating mb-3">
              <select
                value={details?.organization}
                onChange={e => {
                  setdetails({
                    ...details,
                    ["organization"]: e.target.value,
                  })
                }}
                className="form-select"
              >
                <option value="">Choose..</option>
                <option value="Planning">Planning</option>
                <option value="Preparing">Preparing</option>
                <option value="Both">Both</option>
              </select>
              <label htmlFor="floatingSelectGrid">Organization</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingnameInput"
                placeholder=".."
                name="feature"
                value={details?.feature}
                onChange={e => {
                  setdetails({
                    ...details,
                    [e.target.name]: e.target.value,
                  })
                }}
              />
              <label htmlFor="floatingnameInput">Feature</label>
            </div>
            <Row>
              <Col md={6}>
                <div className="form-floating mb-3">
                  <select
                    value={details?.primary_category}
                    onChange={e => {
                      setdetails({
                        ...details,
                        ["primary_category"]: e.target.value,
                      })
                      FindAndSetSecondaryCategory(e.target.value)
                    }}
                    className="form-select"
                  >
                    <option value="">Choose..</option>
                    {primaryCatagoryList.map((data, i) => {
                      return (
                        <>
                          <option key={i} value={data}>
                            {data}
                          </option>
                        </>
                      )
                    })}
                  </select>
                  <label htmlFor="floatingSelectGrid">Primary Category</label>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-floating mb-3">
                  <select
                    value={details?.secondary_category}
                    onChange={e => {
                      setdetails({
                        ...details,
                        ["secondary_category"]: e.target.value,
                      })
                    }}
                    className="form-select"
                  >
                    {secondryCatagoryList[0] && (
                      <option value="">Choose..</option>
                    )}
                    {secondryCatagoryList[0] ? (
                      secondryCatagoryList.map((data, i) => {
                        return (
                          <option key={i} value={data}>
                            {data}
                          </option>
                        )
                      })
                    ) : (
                      <option value="">Choose Primary Category First..</option>
                    )}
                  </select>
                  <label htmlFor="floatingSelectGrid">Secondry Category</label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="form-floating mb-3">
                  <select
                    value={details?.theme}
                    onChange={e => {
                      setdetails({
                        ...details,
                        ["theme"]: e.target.value,
                      })
                    }}
                    className="form-select"
                  >
                    <option value="">Choose..</option>
                    {themeCategoryList.map((data, i) => {
                      return (
                        <>
                          <option key={i} value={data}>
                            {data}
                          </option>
                        </>
                      )
                    })}
                  </select>

                  <label htmlFor="floatingSelectGrid">Theme</label>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-floating mb-3">
                  <select
                    value={details?.variable}
                    onChange={e => {
                      setdetails({
                        ...details,
                        ["variable"]: e.target.value,
                      })
                    }}
                    className="form-select"
                  >
                    <option value="">Choose..</option>
                    {variableCategoryList.map((data, i) => {
                      return (
                        <>
                          <option key={i} value={data}>
                            {data}
                          </option>
                        </>
                      )
                    })}
                  </select>
                  <label htmlFor="floatingSelectGrid">Variable</label>
                </div>
              </Col>
            </Row>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingnameInput"
                placeholder=".."
                name="experience"
                value={details?.experience}
                onChange={e => {
                  setdetails({
                    ...details,
                    [e.target.name]: e.target.value,
                  })
                }}
              />
              <label htmlFor="floatingnameInput">Experience</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingnameInput"
                placeholder=".."
                name="requirement"
                value={details?.requirement}
                onChange={e => {
                  setdetails({
                    ...details,
                    [e.target.name]: e.target.value,
                  })
                }}
              />
              <label htmlFor="floatingnameInput">Requirement</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingnameInput"
                placeholder=".."
                name="sources"
                value={details?.sources}
                onChange={e => {
                  setdetails({
                    ...details,
                    [e.target.name]: e.target.value,
                  })
                }}
              />
              <label htmlFor="floatingnameInput">Sources</label>
            </div>
            <div className="d-flex justify-content-end gap-2">
              <Button
                onClick={toggle}
                className="btn btn-success mx-2 bg-gradient py-1"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="btn btn-success mx-2 bg-gradient py-1"
              >
                {!loading ? (
                  "Submit"
                ) : (
                  <ColorRing
                    visible={true}
                    height="15"
                    width="15"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                  />
                )}
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
      <tr>
        <td>{index + 1}</td>
        <td className="text-center">
          {data.limited ? (
            <i className="bx bx-checkbox-checked font-size-22 text-success"></i>
          ) : (
            <i className="bx bx-checkbox font-size-22 text-success"></i>
          )}
        </td>
        <td className="text-center">
          {data.premium ? (
            <i className="bx bx-checkbox-checked font-size-22 text-success"></i>
          ) : (
            <i className="bx bx-checkbox font-size-22 text-success"></i>
          )}
        </td>
        <td className="text-center">
          {data.enterprise ? (
            <i className="bx bx-checkbox-checked font-size-22 text-success"></i>
          ) : (
            <i className="bx bx-checkbox font-size-22 text-success"></i>
          )}
        </td>
        <td className="text-center">
          {data.organization ? data.organization : "-"}
        </td>
        <td className="text-center">{data.feature ? data.feature : "-"}</td>

        <td className="text-center">
          {data.primary_category ? data.primary_category : "-"}
        </td>
        <td className="text-center">
          {data.secondary_category ? data.secondary_category : "-"}
        </td>
        <td className="text-center">{data.theme ? data.theme : "-"}</td>
        <td className="text-center">
          {data.experience ? data.experience : "-"}
        </td>
        <td className="text-center">{data.variable ? data.variable : "-"}</td>
        <td className="text-center">
          {data.requirement ? data.requirement : "-"}
        </td>
        <td className="text-center">{data.sources ? data.sources : "-"}</td>

        <td className="d-flex gap-2 justify-content-center">
          <i
            className="bx bx-edit-alt"
            onClick={toggle}
            style={{ cursor: "pointer" }}
          ></i>
          <i
            className="bx bx-trash"
            onClick={() =>
              DeletePlaningAndPreparationList({ list_id: data._id })
            }
            style={{ cursor: "pointer" }}
          ></i>
        </td>
      </tr>
    </React.Fragment>
  )
}

export default CardDetails
