import React, { useState } from "react"
import DataContext from "./dataContext"
import axios from "axios"

const DataState = props => {
  const [userList, setUserList] = useState()
  const [masterPackingList, setMasterPackingList] = useState()
  const [masterCategoryList, setMasterCategoryList] = useState()
  const [catalogProductList, setCatalogProductList] = useState()
  const [catalogServiceList, setCatalogServiceList] = useState()
  const [planingAndPreparationList, setPlaningAndPreparationList] = useState()
  const [planingList, setPlaningList] = useState()
  const [routersState, setRoutersState] = useState([])
  const [reload, setreload] = useState(false)

  //  info :Users Api

  const login = async data => {
    var result
    await axios
      .post(`${process.env.REACT_APP_HOST}/admin/login`, data)
      .then(async response => {
        response
        console.log("result", response)
        if (response.status === 200) {
          localStorage.setItem("authUser", response.data.authtoken)
          localStorage.setItem("Type", response.data.type)
          setreload(true)
          result = true
        } else {
          result = false
        }
      })
      .catch(error => {
        console.log(error)
        result = false
      })
    return result
  }
  const signupAdmin = async data => {
    var result
    await axios
      .post(`${process.env.REACT_APP_HOST}/admin/signup-admin`, data)
      .then(response => {
        response
        console.log("result", response)
        if (response.status === 200) {
          localStorage.setItem("email", data.email)
          result = true
        } else {
          result = false
        }
      })
      .catch(error => {
        console.log(error)
        result = false
      })
    return result
  }
  const signupUser = async data => {
    var result
    await axios
      .post(`${process.env.REACT_APP_HOST}/admin/signup-user`, data)
      .then(response => {
        response
        console.log("result", response)
        if (response.status === 200) {
          localStorage.setItem("email", data.email)
          result = true
        } else {
          result = false
        }
      })
      .catch(error => {
        console.log(error)
        result = false
      })
    return result
  }
  const EmailVerification = async data => {
    var result
    await axios
      .post(`${process.env.REACT_APP_HOST}/user/verify-email`, data)
      .then(response => {
        response
        console.log("result", response)
        if (response.status === 200) {
          localStorage.setItem("authUser", response.data.authtoken)
          result = true
        } else {
          result = false
        }
      })
      .catch(error => {
        console.log(error)
        result = false
      })
    return result
  }
  const ReSendOtp = async data => {
    var result
    await axios
      .post(`${process.env.REACT_APP_HOST}/admin/resend-otp`, data)
      .then(response => {
        response
        console.log("result", response)
        if (response.status === 200) {
          result = true
        } else {
          result = false
        }
      })
      .catch(error => {
        console.log(error)
        result = false
      })
    return result
  }
  const ForgetPassword = async data => {
    var result
    await axios
      .post(`${process.env.REACT_APP_HOST}/admin/forgetpassword`, data)
      .then(response => {
        response
        console.log("result", response)
        if (response.status === 200) {
          result = true
        } else {
          result = false
        }
      })
      .catch(error => {
        console.log(error)
        result = false
      })
    return result
  }
  const ResetPassword = async data => {
    var result
    await axios
      .post(`${process.env.REACT_APP_HOST}/admin/resetpassword`, data, {
        headers: {
          Authorization: new URLSearchParams(window.location.search).get(
            "token"
          ),
        },
      })
      .then(response => {
        response
        console.log("result", response)
        if (response.status === 200) {
          result = true
        } else {
          result = false
        }
      })
      .catch(error => {
        console.log(error)
        result = false
      })
    return result
  }
  const GetUsers = async () => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_HOST}/user/getuser`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("authUser"),
            },
          }
        )
        .then(response => {
          var result = response
          console.log("result", result)
          if (result.status == 200 && result?.data?.users.length > 0) {
            setUserList({ status: true, list: result.data.users })
          } else {
            setUserList({ status: false, message: "No Data Found" })
          }
        })
        .catch(error => {
          console.log(error)
          setUserList({ status: false, message: "Internal Server Error" })
        })
    } catch (error) {
      setUserList({ status: false, message: "Internal Server Error" })
    }
  }
  // info  planing and preparation  list api
  const GetPlaningAndPreparationList = async date => {
    try {
      setPlaningAndPreparationList()

      await axios
        .post(
          `${process.env.REACT_APP_HOST}/categories/get_planing_preparation_list`,
          date,
          {
            headers: {
              Authorization: localStorage.getItem("authUser"),
            },
          }
        )
        .then(response => {
          var result = response
          console.log("result", result)
          if (result.status == 200 && result?.data?.list.length > 0) {
            setPlaningAndPreparationList({
              status: true,
              list: result.data.list,
            })
          } else {
            setPlaningAndPreparationList({
              status: false,
              message: "No Data Found",
            })
          }
        })
        .catch(error => {
          console.log(error)
          setPlaningAndPreparationList({
            status: false,
            message: "Internal Server Error",
          })
        })
    } catch (error) {
      setPlaningAndPreparationList({
        status: false,
        message: "Internal Server Error",
      })
    }
  }
  const CreatePlaningAndPreparationList = async date => {
    try {
      let status
      await axios
        .post(
          `${process.env.REACT_APP_HOST}/categories/create_planing_preparation_list`,
          date,
          {
            headers: {
              Authorization: localStorage.getItem("authUser"),
            },
          }
        )
        .then(response => {
          var result = response
          console.log("result", result)
          GetPlaningAndPreparationList()
          status = true
        })
        .catch(error => {
          console.log(error)
          status = false
        })
      return status
    } catch (error) {
      console.log(error)
    }
  }
  const DeletePlaningAndPreparationList = async date => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_HOST}/categories/delete_planing_preparation_list`,
          date,
          {
            headers: {
              Authorization: localStorage.getItem("authUser"),
            },
          }
        )
        .then(response => {
          var result = response
          console.log("result", result)
          GetPlaningAndPreparationList()
        })
        .catch(error => {
          console.log(error)
        })
    } catch (error) {
      console.log(error)
    }
  }

  // info Catelog Service  list api

  const GetCatalogServiceList = async date => {
    try {
      setCatalogServiceList()

      await axios
        .post(
          `${process.env.REACT_APP_HOST}/categories/get_catalog_service_list`,
          date,
          {
            headers: {
              Authorization: localStorage.getItem("authUser"),
            },
          }
        )
        .then(response => {
          var result = response
          console.log("result", result)
          if (result.status == 200 && result?.data?.list.length > 0) {
            setCatalogServiceList({ status: true, list: result.data.list })
          } else {
            setCatalogServiceList({ status: false, message: "No Data Found" })
          }
        })
        .catch(error => {
          console.log(error)
          setCatalogServiceList({
            status: false,
            message: "Internal Server Error",
          })
        })
    } catch (error) {
      setCatalogServiceList({ status: false, message: "Internal Server Error" })
    }
  }
  const CreateCatalogServiceList = async date => {
    try {
      let status
      await axios
        .post(
          `${process.env.REACT_APP_HOST}/categories/create_catalog_service_list`,
          date,
          {
            headers: {
              Authorization: localStorage.getItem("authUser"),
            },
          }
        )
        .then(response => {
          var result = response
          console.log("result", result)
          GetCatalogServiceList()
          status = true
        })
        .catch(error => {
          console.log(error)
          status = false
        })
      return status
    } catch (error) {
      console.log(error)
    }
  }
  const DeleteCatalogServiceList = async date => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_HOST}/categories/delete_catalog_service_list`,
          date,
          {
            headers: {
              Authorization: localStorage.getItem("authUser"),
            },
          }
        )
        .then(response => {
          var result = response
          console.log("result", result)
          GetCatalogServiceList()
        })
        .catch(error => {
          console.log(error)
        })
    } catch (error) {
      console.log(error)
    }
  }
  // info Catelog Product  list api

  const GetCatalogProductList = async date => {
    try {
      setCatalogProductList()

      await axios
        .post(
          `${process.env.REACT_APP_HOST}/categories/get_catalog_product_list`,
          date,
          {
            headers: {
              Authorization: localStorage.getItem("authUser"),
            },
          }
        )
        .then(response => {
          var result = response
          console.log("result", result)
          if (result.status == 200 && result?.data?.list.length > 0) {
            setCatalogProductList({ status: true, list: result.data.list })
          } else {
            setCatalogProductList({ status: false, message: "No Data Found" })
          }
        })
        .catch(error => {
          console.log(error)
          setCatalogProductList({
            status: false,
            message: "Internal Server Error",
          })
        })
    } catch (error) {
      setCatalogProductList({ status: false, message: "Internal Server Error" })
    }
  }
  const CreateCatalogProductList = async date => {
    try {
      let status
      await axios
        .post(
          `${process.env.REACT_APP_HOST}/categories/create_catalog_product_list`,
          date,
          {
            headers: {
              Authorization: localStorage.getItem("authUser"),
            },
          }
        )
        .then(response => {
          var result = response
          console.log("result", result)
          GetCatalogProductList()
          status = true
        })
        .catch(error => {
          console.log(error)
          status = false
        })
      return status
    } catch (error) {
      console.log(error)
    }
  }
  const DeleteCatalogProductList = async date => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_HOST}/categories/delete_catalog_product_list`,
          date,
          {
            headers: {
              Authorization: localStorage.getItem("authUser"),
            },
          }
        )
        .then(response => {
          var result = response
          console.log("result", result)
          GetCatalogProductList()
        })
        .catch(error => {
          console.log(error)
        })
    } catch (error) {
      console.log(error)
    }
  }

  // info Master Packing  list api

  const GetMasterPackingList = async date => {
    try {
      setMasterPackingList()

      await axios
        .post(
          `${process.env.REACT_APP_HOST}/categories/get_master_paking_list`,
          date,
          {
            headers: {
              Authorization: localStorage.getItem("authUser"),
            },
          }
        )
        .then(response => {
          var result = response
          console.log("result", result)
          if (result.status == 200 && result?.data?.list.length > 0) {
            setMasterPackingList({ status: true, list: result.data.list })
          } else {
            setMasterPackingList({ status: false, message: "No Data Found" })
          }
        })
        .catch(error => {
          console.log(error)
          setMasterPackingList({
            status: false,
            message: "Internal Server Error",
          })
        })
    } catch (error) {
      setMasterPackingList({ status: false, message: "Internal Server Error" })
    }
  }
  const CreateMasterPackingList = async date => {
    try {
      let status
      await axios
        .post(
          `${process.env.REACT_APP_HOST}/categories/create_master_paking_list`,
          date,
          {
            headers: {
              Authorization: localStorage.getItem("authUser"),
            },
          }
        )
        .then(response => {
          var result = response
          console.log("result", result)
          GetMasterPackingList()
          status = true
        })
        .catch(error => {
          console.log(error)
          status = false
        })
      return status
    } catch (error) {
      console.log(error)
    }
  }
  const DeleteMasterPackingList = async date => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_HOST}/categories/delete_master_paking_list`,
          date,
          {
            headers: {
              Authorization: localStorage.getItem("authUser"),
            },
          }
        )
        .then(response => {
          var result = response
          console.log("result", result)
          GetMasterPackingList()
        })
        .catch(error => {
          console.log(error)
        })
    } catch (error) {
      console.log(error)
    }
  }
  // info planing list api
  const GetPlaningList = async date => {
    try {
      setPlaningList()

      await axios
        .post(
          `${process.env.REACT_APP_HOST}/categories/get_planing_list`,
          date,
          {
            headers: {
              Authorization: localStorage.getItem("authUser"),
            },
          }
        )
        .then(response => {
          var result = response
          console.log("result", result)
          if (result.status == 200 && result?.data?.list.length > 0) {
            setPlaningList({ status: true, list: result.data.list })
          } else {
            setPlaningList({ status: false, message: "No Data Found" })
          }
        })
        .catch(error => {
          console.log(error)
          setPlaningList({
            status: false,
            message: "Internal Server Error",
          })
        })
    } catch (error) {
      setPlaningList({ status: false, message: "Internal Server Error" })
    }
  }
  const CreatePlaningList = async date => {
    try {
      let status
      await axios
        .post(
          `${process.env.REACT_APP_HOST}/categories/create_planing_list`,
          date,
          {
            headers: {
              Authorization: localStorage.getItem("authUser"),
            },
          }
        )
        .then(response => {
          var result = response
          console.log("result", result)
          GetPlaningList()
          status = true
        })
        .catch(error => {
          console.log(error)
          status = false
        })
      return status
    } catch (error) {
      console.log(error)
    }
  }
  const DeletePlaningList = async date => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_HOST}/categories/delete_planing_list`,
          date,
          {
            headers: {
              Authorization: localStorage.getItem("authUser"),
            },
          }
        )
        .then(response => {
          var result = response
          console.log("result", result)
          GetPlaningList()
        })
        .catch(error => {
          console.log(error)
        })
    } catch (error) {
      console.log(error)
    }
  }

  // info Master Category list api
  const GetMasterCategoryList = async date => {
    try {
      setMasterCategoryList()

      await axios
        .post(
          `${process.env.REACT_APP_HOST}/categories/get_category_list`,
          date,
          {
            headers: {
              Authorization: localStorage.getItem("authUser"),
            },
          }
        )
        .then(response => {
          var result = response
          console.log("result", result)

          if (result.status == 200 && result?.data?.list.length > 0) {
            setMasterCategoryList({ status: true, list: result.data.list })
          } else {
            setMasterCategoryList({ status: false, message: "No Data Found" })
          }
        })
        .catch(error => {
          console.log(error)
          setMasterCategoryList({
            status: false,
            message: "Internal Server Error",
          })
        })
    } catch (error) {
      setMasterCategoryList({ status: false, message: "Internal Server Error" })
    }
  }
  const CreateMasterCategoryList = async date => {
    try {
      let status
      await axios
        .post(
          `${process.env.REACT_APP_HOST}/categories/create_category_list`,
          date,
          {
            headers: {
              Authorization: localStorage.getItem("authUser"),
            },
          }
        )
        .then(response => {
          var result = response
          console.log("result", result)
          GetMasterCategoryList()
          status = true
        })
        .catch(error => {
          console.log(error)
          status = false
        })
      return status
    } catch (error) {
      console.log(error)
    }
  }
  const DeleteMasterCategoryList = async date => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_HOST}/categories/delete_category_list`,
          date,
          {
            headers: {
              Authorization: localStorage.getItem("authUser"),
            },
          }
        )
        .then(response => {
          var result = response
          console.log("result", result)
          GetMasterCategoryList()
        })
        .catch(error => {
          console.log(error)
        })
    } catch (error) {
      console.log(error)
    }
  }

  const DropDownList = async data => {
    let responsedata
    try {
      await axios
        .post(
          `${process.env.REACT_APP_HOST}/categories/get_category_dropdown_list`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("authUser"),
            },
          }
        )
        .then(response => {
          var result = response
          console.log("DropDownList result", result)
          if (result.status === 200) {
            responsedata = { status: true, list: result.data.list }
          }
        })
        .catch(error => {
          console.log(error)
          // setUserList({ status: false, message: "Internal Server Error" })
          responsedata = { status: false, message: "Internal Server Error" }
        })
    } catch (error) {
      console.log(error)
      responsedata = { status: false, message: "Internal Server Error" }

      // setUserList({ status: false, message: "Internal Server Error" })
    }
    return responsedata
  }

  return (
    <DataContext.Provider
      value={{
        reload,
        routersState,
        setRoutersState,
        setUserList,
        userList,
        login,
        signupUser,
        EmailVerification,
        ReSendOtp,
        ForgetPassword,
        ResetPassword,
        signupAdmin,
        GetUsers,
        GetMasterPackingList,
        CreateMasterPackingList,
        masterPackingList,
        DeleteMasterPackingList,
        planingList,
        GetPlaningList,
        CreatePlaningList,
        DeletePlaningList,
        masterCategoryList,
        GetMasterCategoryList,
        CreateMasterCategoryList,
        DeleteMasterCategoryList,
        DropDownList,

        //info: catalog product
        GetCatalogProductList,
        CreateCatalogProductList,
        DeleteCatalogProductList,
        catalogProductList,

        //info: catalog Service
        GetCatalogServiceList,
        CreateCatalogServiceList,
        DeleteCatalogServiceList,
        catalogServiceList,

        //info: planing and preparation
        planingAndPreparationList,
        GetPlaningAndPreparationList,
        CreatePlaningAndPreparationList,
        DeletePlaningAndPreparationList,
      }}
    >
      {props.children}
    </DataContext.Provider>
  )
}

export default DataState
