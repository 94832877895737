import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPassword from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import TwostepVerification from "pages/Authentication/TwostepVerification"
import ResetPassword from "pages/Authentication/ResetPassword"
import AccessProvider from "pages/AccessProvider"
import CreateAdmin from "pages/Create-Admin"
import CreateUser from "pages/Create-User"
import UserList from "pages/Create-User/user-list"
import AdminList from "pages/Create-Admin/admin-list"
import MastePackingList from "pages/MastePackingList"
import PlaningList from "pages/PlaningList"
import CatalogProducts from "pages/Catalog-Products"
import CatalogServices from "pages/Catalog-Services"
import MasterCategory from "pages/MasterCategory"
import PlaningAndPreparation from "pages/PlaningAndPreparation"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard />, name: "Dashboard" },
  { path: "/page-access", component: <AccessProvider />, name: "Page Access" },
  { path: "/create-admin", component: <CreateAdmin />, name: "Create Admin" },
  { path: "/create-user", component: <CreateUser />, name: "Create User" },
  { path: "/user-list", component: <UserList />, name: "User List" },
  { path: "/admin-list", component: <AdminList />, name: "Admin List" },
  {
    path: "/master-category",
    component: <MasterCategory />,
    name: "Master Category",
  },
  {
    path: "/master-packing-list",
    component: <MastePackingList />,
    name: "Master Packing list",
  },
  {
    path: "/planing-list",
    component: <PlaningList />,
    name: "Planing list",
  },
  {
    path: "/catalog-product",
    component: <CatalogProducts />,
    name: "Catalog Product",
  },
  {
    path: "/catalog-services",
    component: <CatalogServices />,
    name: "Catalog Services",
  },
  {
    path: "/planing-preparation",
    component: <PlaningAndPreparation />,
    name: "Planing And Preparation",
  },

  { path: "/profile", component: <UserProfile />, name: "Profile" },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
    name: "Dashboard",
  },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  // { path: "/register", component: <Register /> },
  { path: "/forgot-password", component: <ForgetPassword /> },
  // { path: "/email-verification", component: <TwostepVerification /> },
  { path: "/reset-password", component: <ResetPassword /> },
]

export { authProtectedRoutes, publicRoutes }
