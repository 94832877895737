import React from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import avatar1 from "../../assets/Group.svg"

//i18n
import { withTranslation } from "react-i18next"
const Dashboard = props => {
  //meta title
  document.title = "Dashboard | Skote - React Admin & Dashboard Template"

  const [settingsMenu, setSettingsMenu] = React.useState(false)
  const [pages, setPages] = React.useState([])

  const AddPages = value => {
    setPages([...pages, value])
    console.log(pages)
  }
  const RemovePage = index => {
    const list = [...pages]
    list.splice(index, 1)
    setPages(list)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Page Access Settings")}
          />
          <Row>
            <Col sm={12}>
              <Card>
                <CardBody className="">
                  <CardTitle className="text-uppercase mb-0">Setting</CardTitle>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Card>
            <CardBody className="">
              <Row>
                <Col lg="2">
                  <div className="d-flex">
                    <div className="me-3">
                      <img
                        src={avatar1}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5 className="mb-1">Admin</h5>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col lg="10" className="align-self-center ">
                  <div className="d-flex gap-1 mt-4 mt-lg-0 flex-wrap ">
                    {pages.map((page, key) => (
                      <button
                        key={key}
                        onClick={() => RemovePage(key)}
                        className="btn btn-success "
                      >
                        {page} <i className="bx bx-x align-middle fs-5"></i>
                      </button>
                    ))}

                    <Dropdown
                      isOpen={settingsMenu}
                      toggle={() => {
                        setSettingsMenu(!settingsMenu)
                      }}
                      className="float-end"
                    >
                      <DropdownToggle tag="button" className="btn btn-primary">
                        <i className="bx bx-plus align-middle me-1" /> Pages
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem
                          onClick={() => AddPages("Action")}
                          className={`${pages.includes("Action") && "d-none"}`}
                        >
                          Action
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => AddPages("Another action")}
                          className={`${
                            pages.includes("Another action") && "d-none"
                          }`}
                        >
                          Another action
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => AddPages("Something else")}
                          className={`${
                            pages.includes("Something else") && "d-none"
                          }`}
                        >
                          Something else
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Dashboard)
