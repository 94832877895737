import React, { Fragment } from "react"

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Table,
  CardText,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledTooltip,
  Form,
  Input,
  Label,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Select from "react-select"
import { withTranslation } from "react-i18next"
import dataContext from "context/data/dataContext"
import { Oval, MagnifyingGlass, ColorRing } from "react-loader-spinner"
import CardDetails from "./card-details"

const optionGroup = [
  {
    options: [
      { label: "Item Name", value: "item_name" },
      { label: "Primary Category", value: "primary_category" },
      { label: "Secondry Category", value: "secondry_category" },
      { label: "Timeline", value: "timeline" },
    ],
  },
]

//i18n
const PlaningAndPreparation = props => {
  //meta title
  document.title = "Dashboard | Skote - React Admin & Dashboard Template"

  const {
    planingAndPreparationList,
    GetPlaningAndPreparationList,
    CreatePlaningAndPreparationList,
    DropDownList,
  } = React.useContext(dataContext)

  const [filter, setfilter] = React.useState({
    label: "Item Name",
    value: "item_name",
  })
  const [value, setValue] = React.useState("")
  const [details, setdetails] = React.useState({})
  const [loading, setloading] = React.useState(false)
  const [primaryCatagoryList, setPrimaryCatagoryList] = React.useState([
    "loading...",
  ])
  const [themeCategoryList, setThemeCategoryList] = React.useState([
    "loading...",
  ])
  const [variableCategoryList, setVariableCategoryList] = React.useState([
    "loading...",
  ])
  const [secondryCatagoryList, setSecondryCatagoryList] = React.useState([])
  const [secondryCatagory, setSecondryCatagory] = React.useState([])
  const [modal, setModal] = React.useState(false)
  const toggle = () => {
    setModal(!modal)
    setdetails()
  }
  const GetPrimaryCatagory = async () => {
    let data = await DropDownList({
      category_for: "Planing/Preparation",
      category_type: "Primary Category",
    })
    if (data.status) {
      console.log("data.list :>> ", data.list)
      let makelist = []
      data.list.forEach(element => {
        makelist.push(element.category_value)
      })
      makelist.length > 0 && setPrimaryCatagoryList(makelist)
      makelist.length == 0 &&
        setPrimaryCatagoryList(["No Primary catagory found"])
    } else {
      setPrimaryCatagoryList(["some error found"])
    }
  }
  const GetThemeCatagory = async () => {
    let data = await DropDownList({
      category_for: "Planing/Preparation",
      category_type: "Theme",
    })
    if (data.status) {
      console.log("data.list :>> ", data.list)
      let makelist = []
      data.list.forEach(element => {
        makelist.push(element.category_value)
      })
      makelist.length > 0 && setThemeCategoryList(makelist)
      makelist.length == 0 && setThemeCategoryList(["No Theme catagory found"])
    } else {
      setThemeCategoryList(["some error found"])
    }
  }
  const GetVarableCatagory = async () => {
    let data = await DropDownList({
      category_for: "Planing/Preparation",
      category_type: "Variable",
    })
    if (data.status) {
      console.log("data.list :>> ", data.list)
      let makelist = []
      data.list.forEach(element => {
        makelist.push(element.category_value)
      })
      makelist.length > 0 && setVariableCategoryList(makelist)
      makelist.length == 0 &&
        setVariableCategoryList(["No Theme catagory found"])
    } else {
      setVariableCategoryList(["some error found"])
    }
  }
  const GetSecondryCatagory = async () => {
    let data = await DropDownList({
      category_for: "Planing/Preparation",
      category_type: "Secondry Category",
    })
    if (data.status) {
      console.log("data.list :>> ", data.list)
      setSecondryCatagory(data.list)
    } else {
      setSecondryCatagory([])
    }
  }
  const FindAndSetSecondaryCategory = value => {
    let newList = []
    if (secondryCatagory[0]) {
      secondryCatagory.forEach(item => {
        if (value === item.sub_category_type) {
          newList.push(item.category_value)
        }
      })
      if (newList.length == 0) {
        setSecondryCatagoryList(["no secondary catagory found"])
      } else {
        setSecondryCatagoryList(newList)
      }
    } else {
      setSecondryCatagoryList(["no secondary catagory found"])
    }
  }
  React.useEffect(() => {
    GetPrimaryCatagory()
    GetSecondryCatagory()
    GetThemeCatagory()
    GetVarableCatagory()
  }, [])

  React.useEffect(() => {
    GetPlaningAndPreparationList({ filter: filter.value, value: value })
  }, [value])
  React.useEffect(() => {
    console.log("details", details)
  }, [details])

  return (
    <React.Fragment>
      <Modal
        isOpen={modal}
        fade={false}
        toggle={toggle}
        className="top-50 left-50 mx-auto"
        style={{ transform: "translateY(-50%)" }}
      >
        <ModalHeader toggle={toggle}>
          Create Planing/Preparation List
        </ModalHeader>

        <ModalBody>
          <Form
            className=" mt-2"
            onSubmit={async e => {
              e.preventDefault()
              setloading(true)
              let status = await CreatePlaningAndPreparationList(details)
              status && alert("done")
              status && toggle()
              !status && alert("error")
              setloading(false)
            }}
          >
            <Row>
              <Col sm={4}>
                <div className="mb-3">
                  <div className="form-check ms-1">
                    <Input
                      type="checkbox"
                      className="form-check-Input"
                      id="formrow-customCheck1"
                      checked={details?.limited}
                      onChange={e => {
                        setdetails({
                          ...details,
                          ["limited"]: e.target.checked,
                        })
                      }}
                    />
                    <Label
                      className="form-check-Label"
                      htmlFor="formrow-customCheck1"
                    >
                      Limited
                    </Label>
                  </div>
                </div>{" "}
              </Col>
              <Col sm={4}>
                <div className="mb-3   ">
                  <div
                    className="form-check  mx-auto "
                    style={{ width: "fit-content" }}
                  >
                    <Input
                      type="checkbox"
                      className="form-check-Input"
                      id="formrow-customCheck2"
                      checked={details?.premium}
                      onChange={e => {
                        setdetails({
                          ...details,
                          ["premium"]: e.target.checked,
                        })
                      }}
                    />
                    <Label
                      className="form-check-Label"
                      htmlFor="formrow-customCheck2"
                    >
                      Premium
                    </Label>
                  </div>
                </div>{" "}
              </Col>
              <Col sm={4}>
                <div className="mb-3">
                  <div className="form-check float-end me-1">
                    <Input
                      type="checkbox"
                      className="form-check-Input"
                      id="formrow-customCheck3"
                      checked={details?.enterprise}
                      onChange={e => {
                        setdetails({
                          ...details,
                          ["enterprise"]: e.target.checked,
                        })
                      }}
                    />
                    <Label
                      className="form-check-Label"
                      htmlFor="formrow-customCheck3"
                    >
                      Enterprise
                    </Label>
                  </div>
                </div>{" "}
              </Col>
            </Row>
            <div className="form-floating mb-3">
              <select
                value={details?.organization}
                onChange={e => {
                  setdetails({
                    ...details,
                    ["organization"]: e.target.value,
                  })
                }}
                className="form-select"
              >
                <option value="">Choose..</option>
                <option value="Planning">Planning</option>
                <option value="Preparing">Preparing</option>
                <option value="Both">Both</option>
              </select>
              <label htmlFor="floatingSelectGrid">Organization</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingnameInput"
                placeholder=".."
                name="feature"
                value={details?.feature}
                onChange={e => {
                  setdetails({
                    ...details,
                    [e.target.name]: e.target.value,
                  })
                }}
              />
              <label htmlFor="floatingnameInput">Feature</label>
            </div>
            <Row>
              <Col md={6}>
                <div className="form-floating mb-3">
                  <select
                    value={details?.primary_category}
                    onChange={e => {
                      setdetails({
                        ...details,
                        ["primary_category"]: e.target.value,
                      })
                      FindAndSetSecondaryCategory(e.target.value)
                    }}
                    className="form-select"
                  >
                    <option value="">Choose..</option>
                    {primaryCatagoryList.map((data, i) => {
                      return (
                        <>
                          <option key={i} value={data}>
                            {data}
                          </option>
                        </>
                      )
                    })}
                  </select>
                  <label htmlFor="floatingSelectGrid">Primary Category</label>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-floating mb-3">
                  <select
                    value={details?.secondary_category}
                    onChange={e => {
                      setdetails({
                        ...details,
                        ["secondary_category"]: e.target.value,
                      })
                    }}
                    className="form-select"
                  >
                    {secondryCatagoryList[0] && (
                      <option value="">Choose..</option>
                    )}
                    {secondryCatagoryList[0] ? (
                      secondryCatagoryList.map((data, i) => {
                        return (
                          <option key={i} value={data}>
                            {data}
                          </option>
                        )
                      })
                    ) : (
                      <option value="">Choose Primary Category First..</option>
                    )}
                  </select>
                  <label htmlFor="floatingSelectGrid">Secondry Category</label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="form-floating mb-3">
                  <select
                    value={details?.theme}
                    onChange={e => {
                      setdetails({
                        ...details,
                        ["theme"]: e.target.value,
                      })
                    }}
                    className="form-select"
                  >
                    <option value="">Choose..</option>
                    {themeCategoryList.map((data, i) => {
                      return (
                        <>
                          <option key={i} value={data}>
                            {data}
                          </option>
                        </>
                      )
                    })}
                  </select>

                  <label htmlFor="floatingSelectGrid">Theme</label>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-floating mb-3">
                  <select
                    value={details?.variable}
                    onChange={e => {
                      setdetails({
                        ...details,
                        ["variable"]: e.target.value,
                      })
                    }}
                    className="form-select"
                  >
                    <option value="">Choose..</option>
                    {variableCategoryList.map((data, i) => {
                      return (
                        <>
                          <option key={i} value={data}>
                            {data}
                          </option>
                        </>
                      )
                    })}
                  </select>
                  <label htmlFor="floatingSelectGrid">Variable</label>
                </div>
              </Col>
            </Row>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingnameInput"
                placeholder=".."
                name="experience"
                value={details?.experience}
                onChange={e => {
                  setdetails({
                    ...details,
                    [e.target.name]: e.target.value,
                  })
                }}
              />
              <label htmlFor="floatingnameInput">Experience</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingnameInput"
                placeholder=".."
                name="requirement"
                value={details?.requirement}
                onChange={e => {
                  setdetails({
                    ...details,
                    [e.target.name]: e.target.value,
                  })
                }}
              />
              <label htmlFor="floatingnameInput">Requirement</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingnameInput"
                placeholder=".."
                name="sources"
                value={details?.sources}
                onChange={e => {
                  setdetails({
                    ...details,
                    [e.target.name]: e.target.value,
                  })
                }}
              />
              <label htmlFor="floatingnameInput">Sources</label>
            </div>
            <div className="d-flex justify-content-end gap-2">
              <Button
                onClick={toggle}
                className="btn btn-success mx-2 bg-gradient py-1"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="btn btn-success mx-2 bg-gradient py-1"
              >
                {!loading ? (
                  "Submit"
                ) : (
                  <ColorRing
                    visible={true}
                    height="15"
                    width="15"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                  />
                )}
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Packing List")}
            breadcrumbItem={props.t("Planing List")}
          />
          <Row>
            <Col sm={12}>
              <Card>
                <CardBody className="">
                  <CardTitle className="text-uppercase mb-0">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex gap-2">
                        <div
                          style={{ maxWidth: "120px" }}
                          className="font-size-11"
                        >
                          <Select
                            value={filter}
                            onChange={value => {
                              setValue("")
                              setfilter(value)
                            }}
                            options={optionGroup}
                            className="select2-selection"
                          />
                        </div>

                        <form className="app-search p-0">
                          <div className="position-relative">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={"Search..."}
                              style={{ maxWidth: "300px" }}
                              value={value}
                              onChange={e => setValue(e.target.value)}
                            />
                            <span className="bx bx-search-alt" />
                          </div>
                        </form>
                      </div>
                      <button onClick={toggle} className="btn btn-success ">
                        Create Planing List
                      </button>
                    </div>
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <Fragment>
                <div className="table-responsive react-table">
                  <Table bordered hover className={"custom-header-css mt-3"}>
                    <thead className="table-dark table-nowrap">
                      <tr>
                        <th style={{ width: "40px" }}>#</th>
                        <th className="text-center">Limited</th>
                        <th className="text-center">Premium</th>
                        <th className="text-center">Enterprise</th>
                        <th className="text-center">Organization</th>
                        <th className="text-center">Feature</th>
                        <th className="text-center">Primary Category</th>
                        <th className="text-center">Secondry Category</th>
                        <th className="text-center">Theme</th>
                        <th className="text-center">Experience</th>
                        <th className="text-center">Variable</th>
                        <th className="text-center">Requirement</th>
                        <th className="text-center">Sources</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {!planingAndPreparationList ? (
                        <Fragment>
                          <tr>
                            <td colSpan="12">
                              <div className="d-flex justify-content-center py-4">
                                <Oval
                                  height={40}
                                  width={40}
                                  color="#4fa94d"
                                  wrapperStyle={{}}
                                  wrapperClass=""
                                  visible={true}
                                  ariaLabel="oval-loading"
                                  secondaryColor="#4fa94d"
                                  strokeWidth={5}
                                  strokeWidthSecondary={5}
                                />
                              </div>
                            </td>
                          </tr>
                        </Fragment>
                      ) : planingAndPreparationList.status ? (
                        planingAndPreparationList.list.map((data, i) => (
                          <CardDetails
                            data={data}
                            index={i}
                            key={i}
                            primaryCatagoryList={primaryCatagoryList}
                            secondryCatagory={secondryCatagory}
                            themeCategoryList={themeCategoryList}
                            variableCategoryList={variableCategoryList}
                          />
                        ))
                      ) : (
                        <>
                          <tr>
                            <td colSpan="12" className="text-center">
                              <div className="d-inline-block mx-auto  my-5">
                                <CardTitle className="mt-0">
                                  <MagnifyingGlass
                                    visible={true}
                                    height="80"
                                    width="80"
                                    ariaLabel="MagnifyingGlass-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="MagnifyingGlass-wrapper"
                                    glassColor="#c0efff"
                                    color="#e15b64"
                                  />
                                </CardTitle>
                                <CardText className="text-danger">
                                  {planingAndPreparationList?.message}
                                </CardText>
                              </div>
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Fragment>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(PlaningAndPreparation)
