import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"

//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"

import { Link } from "react-router-dom"

import logo from "../../assets/ilogo.png"
import logoLightPng from "../../assets/Tripsave-01.png"
// import logoLightPng from "../../assets/t_logo.png"

const Sidebar = props => {
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img
                src={logo}
                alt=""
                height="30"
                style={{ filter: "drop-shadow(2px 2px 5px #fff)" }}
              />
            </span>
            <span className="logo-lg">
              <img
                src={logoLightPng}
                alt=""
                height="90"
                style={{ filter: "drop-shadow(2px 2px 5px #fff)" }}
              />
              {/* <img src={logoLightPng} alt="" height="35" /> */}
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
