import React, { Fragment } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Table,
  CardText,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledTooltip,
  Form,
} from "reactstrap"
import { Oval, MagnifyingGlass, ColorRing } from "react-loader-spinner"
import { withTranslation } from "react-i18next"

import Select from "react-select"
import axios from "axios"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import dataContext from "context/data/dataContext"
import CardDetails from "./card-details"

const optionGroup = [
  {
    options: [
      { label: "Category For", value: "category_for" },
      { label: "Category Type", value: "category_type" },
      { label: "Category Value", value: "category_value" },
    ],
  },
]

const MasterCategory = props => {
  const {
    masterCategoryList,
    GetMasterCategoryList,
    CreateMasterCategoryList,
    DropDownList,
  } = React.useContext(dataContext)

  //meta title
  document.title = "Dashboard | Skote - React Admin & Dashboard Template"

  const [loading, setloading] = React.useState(false)
  const [categoryTypeList, setcategoryTypeList] = React.useState([])
  const [primaryCatagoryList, setPrimaryCatagoryList] = React.useState([])
  const [newdetails, setNewdetails] = React.useState({})
  const [value, setValue] = React.useState("")
  const [showHidePrimary, setShowHidePrimary] = React.useState(true)
  const [modal, setModal] = React.useState(false)
  const [filter, setfilter] = React.useState({
    label: "Category For",
    value: "category_for",
  })

  const toggle = () => {
    setModal(!modal)
    setNewdetails()
  }
  const GetPrimaryCatagory = async (
    categoryFor,
    categorType = "Primary Category"
  ) => {
    if (masterCategoryList?.status) {
      let newlist = []
      if (masterCategoryList.list.length > 0) {
        masterCategoryList.list.forEach(element => {
          if (
            categoryFor === element.category_for &&
            categorType === element.category_type
          ) {
            newlist.push(element.category_value)
          }
        })
        newlist.length > 0 && setPrimaryCatagoryList(newlist)
        newlist.length == 0 &&
          setPrimaryCatagoryList(["No Primary Catagory Found"])
      } else {
        setPrimaryCatagoryList(["No Primary Catagory Found"])
      }
    } else {
      setPrimaryCatagoryList(["some error found"])
    }
  }
  const FindAndSetCategoryTypeList = value => {
    if (value == "Master Packing List") {
      setcategoryTypeList(["Primary Category", "Secondry Category"])
    } else if (value == "Planing List") {
      setcategoryTypeList(["Primary Category", "Secondry Category", "Timeline"])
    } else if (value == "Catalog Product") {
      setcategoryTypeList(["Primary Category", "Secondry Category"])
    } else if (value == "Catalog Services") {
      setcategoryTypeList(["Primary Category", "Secondry Category"])
    } else if (value == "Planing/Preparation") {
      setcategoryTypeList([
        "Primary Category",
        "Secondry Category",
        "Theme",
        "Variable",
      ])
    } else {
      setcategoryTypeList(["On Development"])
    }
  }
  const FindAndSetSecondryCategoryList = value => {
    if (value == "Secondry Category") {
      setShowHidePrimary(false)
    } else {
      setShowHidePrimary(true)
    }
  }

  React.useEffect(() => {
    GetMasterCategoryList({ filter: filter.value, value: value })
  }, [value])

  return (
    <React.Fragment>
      <Modal
        isOpen={modal}
        fade={false}
        toggle={toggle}
        className="top-50 left-50 mx-auto"
        style={{ transform: "translateY(-50%)" }}
      >
        <ModalHeader toggle={toggle}>Create Master Category List</ModalHeader>

        <ModalBody>
          <Form
            className=" mt-2"
            onSubmit={async e => {
              e.preventDefault()
              setloading(true)
              console.log("details :>> ", newdetails)
              let status = await CreateMasterCategoryList(newdetails)
              status && alert("done")
              status && toggle()
              status &&
                //   !status && alert("error")
                setloading(false)
            }}
          >
            <Row>
              <Col md={6}>
                <div className="form-floating mb-3">
                  <select
                    value={newdetails?.category_for}
                    onChange={e => {
                      setNewdetails({
                        ...newdetails,
                        ["category_for"]: e.target.value,
                        ["category_type"]: "",
                      })
                      FindAndSetCategoryTypeList(e.target.value)
                      GetPrimaryCatagory(e.target.value)

                      setShowHidePrimary(true)
                    }}
                    className="form-select"
                  >
                    <option value="">Choose..</option>

                    <option value="Catalog Product">Catalog Product</option>
                    <option value="Catalog Services">Catalog Services</option>
                    <option value="Master Packing List">
                      Master Packing List
                    </option>
                    <option value="Planing List">Planing List</option>
                    <option value="Planing/Preparation">
                      Planing/Preparation
                    </option>
                  </select>
                  <label htmlFor="floatingSelectGrid">Category For</label>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-floating mb-3">
                  <select
                    value={newdetails?.category_type}
                    onChange={e => {
                      setNewdetails({
                        ...newdetails,
                        ["category_type"]: e.target.value,
                      })
                      FindAndSetSecondryCategoryList(e.target.value)
                    }}
                    className="form-select"
                  >
                    {categoryTypeList[0] && <option value="">Choose..</option>}
                    {categoryTypeList[0] ? (
                      categoryTypeList.map((data, i) => {
                        return (
                          <option key={i} value={data}>
                            {data}
                          </option>
                        )
                      })
                    ) : (
                      <option value="">Choose Category For First..</option>
                    )}
                  </select>
                  <label htmlFor="floatingSelectGrid">Category Type</label>
                </div>
              </Col>
            </Row>
            <div
              className={`${showHidePrimary && "d-none"} form-floating mb-3`}
            >
              <select
                value={newdetails?.sub_category_type}
                onChange={e => {
                  setNewdetails({
                    ...newdetails,
                    ["sub_category_type"]: e.target.value,
                  })
                }}
                className="form-select"
              >
                {primaryCatagoryList[0] && <option value="">Choose..</option>}
                {primaryCatagoryList.map((data, i) => {
                  return (
                    <option key={i} value={data}>
                      {data}
                    </option>
                  )
                })}
              </select>
              <label htmlFor="floatingSelectGrid">
                Select Dependent Primary Category
              </label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingnameInput"
                placeholder="Enter Category Value"
                name="category_value"
                value={newdetails?.category_value}
                onChange={e => {
                  setNewdetails({
                    ...newdetails,
                    [e.target.name]: e.target.value,
                  })
                }}
              />
              <label htmlFor="floatingnameInput">Category Value</label>
            </div>

            <div className="d-flex justify-content-end gap-2">
              <Button
                onClick={toggle}
                className="btn btn-success mx-2 bg-gradient py-1"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="btn btn-success mx-2 bg-gradient py-1"
              >
                {!loading ? (
                  "Submit"
                ) : (
                  <ColorRing
                    visible={true}
                    height="15"
                    width="15"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                  />
                )}
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Master Category")}
          />
          <Row>
            <Col sm={12}>
              <Card>
                <CardBody className="">
                  <CardTitle className="text-uppercase mb-0">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex gap-2">
                        <div
                          style={{ maxWidth: "120px" }}
                          className="font-size-11"
                        >
                          <Select
                            value={filter}
                            onChange={value => {
                              setValue("")
                              setfilter(value)
                            }}
                            options={optionGroup}
                            className="select2-selection "
                          />
                        </div>

                        <form className="app-search p-0">
                          <div className="position-relative">
                            <input
                              type="text"
                              className="form-control rounded"
                              placeholder={"Search..."}
                              style={{ maxWidth: "300px" }}
                              value={value}
                              onChange={e => setValue(e.target.value)}
                            />
                            <span className="bx bx-search-alt" />
                          </div>
                        </form>
                      </div>
                      <button onClick={toggle} className="btn btn-success ">
                        Create Category List
                      </button>
                    </div>
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <Fragment>
                <div className="table-responsive react-table">
                  <Table bordered hover className={"custom-header-css mt-3"}>
                    <thead className="table-dark table-nowrap">
                      <tr>
                        <th style={{ width: "40px" }}>#</th>
                        <th>Category For</th>
                        <th className="text-center">Category Type</th>
                        <th className="text-center">Category Value</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {!masterCategoryList ? (
                        <Fragment>
                          <tr>
                            <td colSpan="12">
                              <div className="d-flex justify-content-center py-4">
                                <Oval
                                  height={40}
                                  width={40}
                                  color="#4fa94d"
                                  wrapperStyle={{}}
                                  wrapperClass=""
                                  visible={true}
                                  ariaLabel="oval-loading"
                                  secondaryColor="#4fa94d"
                                  strokeWidth={5}
                                  strokeWidthSecondary={5}
                                />
                              </div>
                            </td>
                          </tr>
                        </Fragment>
                      ) : masterCategoryList.status ? (
                        masterCategoryList.list.map((data, i) => (
                          <CardDetails
                            data={data}
                            index={i}
                            key={i}
                            primaryCatagoryList={primaryCatagoryList}
                          />
                        ))
                      ) : (
                        <>
                          <tr>
                            <td colSpan="12" className="text-center">
                              <div className="d-inline-block mx-auto  my-5">
                                <CardTitle className="mt-0">
                                  <MagnifyingGlass
                                    visible={true}
                                    height="80"
                                    width="80"
                                    ariaLabel="MagnifyingGlass-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="MagnifyingGlass-wrapper"
                                    glassColor="#c0efff"
                                    color="#e15b64"
                                  />
                                </CardTitle>
                                <CardText className="text-danger">
                                  {masterCategoryList?.message}
                                </CardText>
                              </div>
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Fragment>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(MasterCategory)
