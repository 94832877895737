import React from "react"
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledTooltip,
  Form,
  Row,
  Col,
  Input,
} from "reactstrap"
import { Watch, ThreeDots, ColorRing } from "react-loader-spinner"
import axios from "axios"
import dataContext from "context/data/dataContext"

const CardDetails = ({
  data,
  index,
  primaryCatagoryList,
  secondryCatagory,
}) => {
  const { GetCatalogProductList, DeleteCatalogProductList } =
    React.useContext(dataContext)
  const [secondryCatagoryList, setSecondryCatagoryList] = React.useState([])

  const [loading, setloading] = React.useState(false)
  const [details, setdetails] = React.useState(data)

  const [modal, setModal] = React.useState(false)
  const toggle = () => {
    setModal(!modal)
    setdetails(data)
  }
  const FindAndSetSecondaryCategory = value => {
    let newList = []
    if (secondryCatagory[0]) {
      secondryCatagory.forEach(item => {
        if (value === item.sub_category_type) {
          newList.push(item.category_value)
        }
      })
      if (newList.length == 0) {
        setSecondryCatagoryList(["no secondary catagory found"])
      } else {
        setSecondryCatagoryList(newList)
      }
    } else {
      setSecondryCatagoryList(["no secondary catagory found"])
    }
  }

  const UpdateUser = async () => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_HOST}/categories/update_catalog_product_list`,
          details,
          {
            headers: {
              Authorization: localStorage.getItem("authUser"),
            },
          }
        )
        .then(response => {
          var result = response
          console.log("result", result)
          GetCatalogProductList()

          setloading(false)
          toggle()
        })
        .catch(error => {
          console.log(error)
          alert("error")
        })
    } catch (error) {
      console.log(error)
      alert("error")
    }
  }

  React.useEffect(() => {
    FindAndSetSecondaryCategory(data.primary_category)
  }, [data])
  return (
    <React.Fragment>
      <Modal
        isOpen={modal}
        fade={false}
        toggle={toggle}
        className="top-50 left-50 mx-auto"
        style={{ transform: "translateY(-50%)" }}
      >
        <ModalHeader toggle={toggle}>Update Catalog Product List</ModalHeader>

        <ModalBody>
          <Form
            className=" mt-2"
            onSubmit={async e => {
              e.preventDefault()
              setloading(true)
              UpdateUser(details)
            }}
          >
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingnameInput"
                placeholder="Enter Item Name"
                name="item_name"
                value={details?.item_name}
                onChange={e => {
                  setdetails({
                    ...details,
                    [e.target.name]: e.target.value,
                  })
                }}
              />
              <label htmlFor="floatingnameInput">Item Name</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingnameInput1"
                placeholder="Enter Item Description"
                name="item_description"
                value={details?.item_description}
                onChange={e => {
                  setdetails({
                    ...details,
                    [e.target.name]: e.target.value,
                  })
                }}
              />
              <label htmlFor="floatingnameInput1">Item Description</label>
            </div>

            <Row>
              <Col md={6}>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingnameInput1"
                    placeholder="Price / Price Range"
                    name="price_pricerange"
                    value={details?.price_pricerange}
                    onChange={e => {
                      setdetails({
                        ...details,
                        [e.target.name]: e.target.value,
                      })
                    }}
                  />
                  <label htmlFor="floatingnameInput1">
                    Price / Price Range
                  </label>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-floating mb-3">
                  <select
                    value={details?.trip_sava_recommended}
                    onChange={e => {
                      setdetails({
                        ...details,
                        ["trip_sava_recommended"]: e.target.value,
                      })
                      FindAndSetSecondaryCategory(e.target.value)
                    }}
                    className="form-select"
                  >
                    <option value="">Choose..</option>
                    <option value="YES">YES</option>
                    <option value="NO">NO</option>
                  </select>
                  <label htmlFor="floatingSelectGrid">
                    Trip-sava Recommended
                  </label>
                </div>
              </Col>
            </Row>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingnameInput1"
                placeholder="Enter pro Tip"
                name="trip_sava_recommendation"
                value={details?.trip_sava_recommendation}
                onChange={e => {
                  setdetails({
                    ...details,
                    [e.target.name]: e.target.value,
                  })
                }}
              />
              <label htmlFor="floatingnameInput1">
                {" "}
                Trip-sava Recommendation
              </label>
            </div>
            <Row>
              <Col md={6}>
                <div className="form-floating mb-3">
                  <select
                    value={details?.primary_category}
                    onChange={e => {
                      setdetails({
                        ...details,
                        ["primary_category"]: e.target.value,
                      })
                      FindAndSetSecondaryCategory(e.target.value)
                    }}
                    className="form-select"
                  >
                    <option value="">Choose..</option>
                    {primaryCatagoryList.map((data, i) => {
                      return (
                        <>
                          <option key={i} value={data}>
                            {data}
                          </option>
                        </>
                      )
                    })}
                  </select>
                  <label htmlFor="floatingSelectGrid">Primary Category</label>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-floating mb-3">
                  <select
                    value={details?.secondary_category}
                    onChange={e => {
                      setdetails({
                        ...details,
                        ["secondary_category"]: e.target.value,
                      })
                    }}
                    className="form-select"
                  >
                    {secondryCatagoryList[0] && (
                      <option value="">Choose..</option>
                    )}
                    {secondryCatagoryList[0] ? (
                      secondryCatagoryList.map((data, i) => {
                        return (
                          <option key={i} value={data}>
                            {data}
                          </option>
                        )
                      })
                    ) : (
                      <option value="">Choose Primary Category First..</option>
                    )}
                  </select>
                  <label htmlFor="floatingSelectGrid">Secondry Category</label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingnameInput1"
                    placeholder="Enter pro Tip"
                    name="review"
                    value={details?.review}
                    onChange={e => {
                      setdetails({
                        ...details,
                        [e.target.name]: e.target.value,
                      })
                    }}
                  />
                  <label htmlFor="floatingnameInput1">Review</label>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-floating mb-3">
                  <select
                    value={details?.product_of_month}
                    onChange={e => {
                      setdetails({
                        ...details,
                        ["product_of_month"]: e.target.value,
                      })
                      // FindAndSetSecondaryCategory(e.target.value)
                    }}
                    className="form-select"
                  >
                    <option value="">Choose..</option>
                    <option value="YES">YES</option>
                    <option value="NO">NO</option>
                  </select>
                  <label htmlFor="floatingSelectGrid">Product of Month</label>
                </div>
              </Col>
            </Row>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingnameInput1"
                placeholder="Enter pro Tip"
                name="youtube_links"
                value={details?.youtube_links}
                onChange={e => {
                  setdetails({
                    ...details,
                    [e.target.name]: e.target.value,
                  })
                }}
              />
              <label htmlFor="floatingnameInput1">Youtube Links</label>
            </div>
            <div className="form-floating mb-3">
              <div className="mt-3 mb-2">Image</div>

              <Col md="12">
                <div className="mt-4 mt-md-0">
                  <Input
                    type="file"
                    className="form-control"
                    // accept=".txt,.csv"
                    onChange={e => {
                      // setTextFileName(e.target.files[0])
                      // handleChangeFile(e.target.files[0])
                    }}
                  />
                </div>
              </Col>
            </div>

            <div className="d-flex justify-content-end gap-2">
              <Button
                onClick={toggle}
                className="btn btn-success mx-2 bg-gradient py-1"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="btn btn-success mx-2 bg-gradient py-1"
              >
                {!loading ? (
                  "Submit"
                ) : (
                  <ColorRing
                    visible={true}
                    height="15"
                    width="15"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                  />
                )}
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
      <tr>
        <td>{index + 1}</td>
        <td>{data.item_name}</td>
        <td className="text-center">
          {data.item_description ? data.item_description : "-"}
        </td>
        <td></td>
        <td className="text-center">
          {data.price_pricerange ? data.price_pricerange : "-"}
        </td>
        <td className="text-center">
          {data.trip_sava_recommended ? data.trip_sava_recommended : "-"}
        </td>
        <td className="text-center">
          {data.trip_sava_recommendation ? data.trip_sava_recommendation : "-"}
        </td>

        <td className="text-center">
          {data.primary_category ? data.primary_category : "-"}
        </td>
        <td className="text-center">
          {data.secondary_category ? data.secondary_category : "-"}
        </td>
        <td className="text-center">{data.review ? data.review : "-"}</td>
        <td className="text-center">
          {data.product_of_month ? data.product_of_month : "-"}
        </td>
        <td className="text-center">
          {data.youtube_links ? data.youtube_links : "-"}
        </td>

        <td className="d-flex gap-2 justify-content-center">
          <i
            className="bx bx-edit-alt"
            onClick={toggle}
            style={{ cursor: "pointer" }}
          ></i>
          <i
            className="bx bx-trash"
            onClick={() => DeleteCatalogProductList({ list_id: data._id })}
            style={{ cursor: "pointer" }}
          ></i>
        </td>
      </tr>
    </React.Fragment>
  )
}

export default CardDetails
