import React, { Fragment } from "react"

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Table,
  CardText,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledTooltip,
  Form,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Select from "react-select"
import { withTranslation } from "react-i18next"
import dataContext from "context/data/dataContext"
import { Oval, MagnifyingGlass, ColorRing } from "react-loader-spinner"
import CardDetails from "./card-details"

const optionGroup = [
  {
    options: [
      { label: "Item Name", value: "item_name" },
      { label: "Primary Category", value: "primary_category" },
      { label: "Secondry Category", value: "secondry_category" },
      { label: "Timeline", value: "timeline" },
    ],
  },
]

//i18n
const PlaningList = props => {
  //meta title
  document.title = "Dashboard | Skote - React Admin & Dashboard Template"

  const { planingList, GetPlaningList, CreatePlaningList, DropDownList } =
    React.useContext(dataContext)

  const [filter, setfilter] = React.useState({
    label: "Item Name",
    value: "item_name",
  })
  const [value, setValue] = React.useState("")
  const [details, setdetails] = React.useState({
    item_name: "",
    pro_tip: "",
    primary_category: "",
    secondary_category: "",
    timeline: "",
  })
  const [loading, setloading] = React.useState(false)
  const [primaryCatagoryList, setPrimaryCatagoryList] = React.useState([
    "loading...",
  ])
  const [timelineCatagoryList, setTimelineCatagoryList] = React.useState([
    "loading...",
  ])
  const [secondryCatagoryList, setSecondryCatagoryList] = React.useState([])
  const [secondryCatagory, setSecondryCatagory] = React.useState([])
  const [modal, setModal] = React.useState(false)
  const toggle = () => {
    setModal(!modal)
    setdetails()
  }
  const GetPrimaryCatagory = async () => {
    let data = await DropDownList({
      category_for: "Planing List",
      category_type: "Primary Category",
    })
    if (data.status) {
      console.log("data.list :>> ", data.list)
      let makelist = []
      data.list.forEach(element => {
        makelist.push(element.category_value)
      })
      makelist.length > 0 && setPrimaryCatagoryList(makelist)
      makelist.length == 0 &&
        setPrimaryCatagoryList(["No Primary catagory found"])
    } else {
      setPrimaryCatagoryList(["some error found"])
    }
  }
  const GetTimelineCatagory = async () => {
    let data = await DropDownList({
      category_for: "Planing List",
      category_type: "Timeline",
    })
    if (data.status) {
      console.log("data.list :>> ", data.list)
      let makelist = []
      data.list.forEach(element => {
        makelist.push(element.category_value)
      })
      makelist.length > 0 && setTimelineCatagoryList(makelist)
      makelist.length == 0 &&
        setTimelineCatagoryList(["No Timeline catagory found"])
    } else {
      setTimelineCatagoryList(["some error found"])
    }
  }
  const GetSecondryCatagory = async () => {
    let data = await DropDownList({
      category_for: "Planing List",
      category_type: "Secondry Category",
    })
    if (data.status) {
      console.log("data.list :>> ", data.list)
      setSecondryCatagory(data.list)
    } else {
      setSecondryCatagory([])
    }
  }
  const FindAndSetSecondaryCategory = value => {
    let newList = []
    if (secondryCatagory[0]) {
      secondryCatagory.forEach(item => {
        if (value === item.sub_category_type) {
          newList.push(item.category_value)
        }
      })
      if (newList.length == 0) {
        setSecondryCatagoryList(["no secondary catagory found"])
      } else {
        setSecondryCatagoryList(newList)
      }
    } else {
      setSecondryCatagoryList(["no secondary catagory found"])
    }
  }
  React.useEffect(() => {
    GetPrimaryCatagory()
    GetSecondryCatagory()
    GetTimelineCatagory()
  }, [])

  React.useEffect(() => {
    GetPlaningList({ filter: filter.value, value: value })
  }, [value])

  return (
    <React.Fragment>
      <Modal
        isOpen={modal}
        fade={false}
        toggle={toggle}
        className="top-50 left-50 mx-auto"
        style={{ transform: "translateY(-50%)" }}
      >
        <ModalHeader toggle={toggle}>Create Planing List</ModalHeader>

        <ModalBody>
          <Form
            className=" mt-2"
            onSubmit={async e => {
              e.preventDefault()
              setloading(true)
              let status = await CreatePlaningList(details)
              status && alert("done")
              status && toggle()
              !status && alert("error")
              setloading(false)
            }}
          >
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingnameInput"
                placeholder="Enter Item Name"
                name="item_name"
                value={details?.item_name}
                onChange={e => {
                  setdetails({
                    ...details,
                    [e.target.name]: e.target.value,
                  })
                }}
              />
              <label htmlFor="floatingnameInput">Item Name</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingnameInput1"
                placeholder="Enter pro Tip"
                name="pro_tip"
                value={details?.pro_tip}
                onChange={e => {
                  setdetails({
                    ...details,
                    [e.target.name]: e.target.value,
                  })
                }}
              />
              <label htmlFor="floatingnameInput1">Pro Tip</label>
            </div>

            <Row>
              <Col md={6}>
                <div className="form-floating mb-3">
                  <select
                    value={details?.primary_category}
                    onChange={e => {
                      setdetails({
                        ...details,
                        ["primary_category"]: e.target.value,
                      })
                      FindAndSetSecondaryCategory(e.target.value)
                    }}
                    className="form-select"
                  >
                    <option value="">Choose..</option>
                    {primaryCatagoryList.map((data, i) => {
                      return (
                        <>
                          <option key={i} value={data}>
                            {data}
                          </option>
                        </>
                      )
                    })}
                  </select>
                  <label htmlFor="floatingSelectGrid">Primary Category</label>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-floating mb-3">
                  <select
                    value={details?.secondary_category}
                    onChange={e => {
                      setdetails({
                        ...details,
                        ["secondary_category"]: e.target.value,
                      })
                    }}
                    className="form-select"
                  >
                    {secondryCatagoryList[0] && (
                      <option value="">Choose..</option>
                    )}
                    {secondryCatagoryList[0] ? (
                      secondryCatagoryList.map((data, i) => {
                        return (
                          <option key={i} value={data}>
                            {data}
                          </option>
                        )
                      })
                    ) : (
                      <option value="">Choose Primary Category First..</option>
                    )}
                  </select>
                  <label htmlFor="floatingSelectGrid">Secondry Category</label>
                </div>
              </Col>
            </Row>
            <div className="form-floating mb-3">
              <select
                value={details?.timeline}
                onChange={e => {
                  setdetails({
                    ...details,
                    ["timeline"]: e.target.value,
                  })
                }}
                className="form-select"
              >
                <option value="">Choose..</option>
                {timelineCatagoryList.map((data, i) => {
                  return (
                    <>
                      <option key={i} value={data}>
                        {data}
                      </option>
                    </>
                  )
                })}
              </select>

              <label htmlFor="floatingSelectGrid">Timeline</label>
            </div>

            <div className="d-flex justify-content-end gap-2">
              <Button
                onClick={toggle}
                className="btn btn-success mx-2 bg-gradient py-1"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="btn btn-success mx-2 bg-gradient py-1"
              >
                {!loading ? (
                  "Submit"
                ) : (
                  <ColorRing
                    visible={true}
                    height="15"
                    width="15"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                  />
                )}
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Packing List")}
            breadcrumbItem={props.t("Planing List")}
          />
          <Row>
            <Col sm={12}>
              <Card>
                <CardBody className="">
                  <CardTitle className="text-uppercase mb-0">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex gap-2">
                        <div
                          style={{ maxWidth: "120px" }}
                          className="font-size-11"
                        >
                          <Select
                            value={filter}
                            onChange={value => {
                              setValue("")
                              setfilter(value)
                            }}
                            options={optionGroup}
                            className="select2-selection"
                          />
                        </div>

                        <form className="app-search p-0">
                          <div className="position-relative">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={"Search..."}
                              style={{ maxWidth: "300px" }}
                              value={value}
                              onChange={e => setValue(e.target.value)}
                            />
                            <span className="bx bx-search-alt" />
                          </div>
                        </form>
                      </div>
                      <button onClick={toggle} className="btn btn-success ">
                        Create Planing List
                      </button>
                    </div>
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <Fragment>
                <div className="table-responsive react-table">
                  <Table bordered hover className={"custom-header-css mt-3"}>
                    <thead className="table-dark table-nowrap">
                      <tr>
                        <th style={{ width: "40px" }}>#</th>
                        <th>Item Name</th>
                        <th className="text-center">Pro Tip</th>
                        <th className="text-center">Primary Category</th>
                        <th className="text-center">Secondry Category</th>
                        <th className="text-center">Timeline</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {!planingList ? (
                        <Fragment>
                          <tr>
                            <td colSpan="12">
                              <div className="d-flex justify-content-center py-4">
                                <Oval
                                  height={40}
                                  width={40}
                                  color="#4fa94d"
                                  wrapperStyle={{}}
                                  wrapperClass=""
                                  visible={true}
                                  ariaLabel="oval-loading"
                                  secondaryColor="#4fa94d"
                                  strokeWidth={5}
                                  strokeWidthSecondary={5}
                                />
                              </div>
                            </td>
                          </tr>
                        </Fragment>
                      ) : planingList.status ? (
                        planingList.list.map((data, i) => (
                          <CardDetails
                            data={data}
                            index={i}
                            key={i}
                            primaryCatagoryList={primaryCatagoryList}
                            secondryCatagory={secondryCatagory}
                            timelineCatagoryList={timelineCatagoryList}
                          />
                        ))
                      ) : (
                        <>
                          <tr>
                            <td colSpan="12" className="text-center">
                              <div className="d-inline-block mx-auto  my-5">
                                <CardTitle className="mt-0">
                                  <MagnifyingGlass
                                    visible={true}
                                    height="80"
                                    width="80"
                                    ariaLabel="MagnifyingGlass-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="MagnifyingGlass-wrapper"
                                    glassColor="#c0efff"
                                    color="#e15b64"
                                  />
                                </CardTitle>
                                <CardText className="text-danger">
                                  {planingList?.message}
                                </CardText>
                              </div>
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Fragment>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(PlaningList)
