import React, { Fragment } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Table,
  CardText,
  Label,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Oval, MagnifyingGlass } from "react-loader-spinner"
//i18n
import { withTranslation } from "react-i18next"
import dataContext from "context/data/dataContext"
import UserDetails from "./admin-details"
import axios from "axios"
import Select from "react-select"
const optionGroup = [
  {
    options: [
      { label: "Name", value: "name" },
      { label: "Email", value: "email" },
      { label: "Phone No.", value: "phone" },
      { label: "Status", value: "status" },
    ],
  },
]
const AdminList = props => {
  const { GetUsers, userList, setUserList } = React.useContext(dataContext)
  //meta title
  document.title = "Dashboard | Skote - React Admin & Dashboard Template"

  const [filter, setfilter] = React.useState({ label: "Name", value: "name" })
  const [value, setValue] = React.useState("")

  React.useEffect(() => {
    const cancelToken = axios.CancelToken.source()
    axios
      .get(
        `${process.env.REACT_APP_HOST}/admin/getadmins?&filter=${
          filter.value
        }&value=${filter.value === "phone" ? Number(value) : value}`,
        {
          cancelToken: cancelToken.token,
        }
      )
      .then(function (response) {
        var result = response
        console.log("result", result)
        if (result.status == 200 && result?.data?.admins.length > 0) {
          setUserList({ status: true, list: result.data.admins })
        } else {
          setUserList({ status: false, message: "No Data Found" })
        }
      })
      .catch(error => {
        console.log(error)
        setUserList({ status: false, message: "Internal Server Error" })
      })

    return () => {
      // cancel the request
      cancelToken.cancel()
    }
  }, [value])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("User List")}
          />
          <Row>
            <Col sm={12}>
              <Card>
                <CardBody className="">
                  <CardTitle className="text-uppercase mb-0">
                    <div className="d-flex gap-2">
                      <div
                        style={{ maxWidth: "120px" }}
                        className="font-size-11"
                      >
                        <Select
                          value={filter}
                          onChange={value => {
                            setValue("")
                            setfilter(value)
                          }}
                          options={optionGroup}
                          className="select2-selection"
                        />
                      </div>

                      <form className="app-search p-0">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={"Search..."}
                            style={{ maxWidth: "300px" }}
                            value={value}
                            onChange={e => setValue(e.target.value)}
                          />
                          <span className="bx bx-search-alt" />
                        </div>
                      </form>
                    </div>
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <Fragment>
                <div className="table-responsive react-table">
                  <Table bordered hover className={"custom-header-css mt-3"}>
                    <thead className="table-dark table-nowrap">
                      <tr>
                        <th style={{ width: "40px" }}>#</th>
                        <th>Name</th>
                        <th className="text-center">Email</th>
                        <th className="text-center">Phone No.</th>
                        <th className="text-center">Status</th>
                        <th className="text-center">Date</th>
                        <th className="text-center"></th>
                      </tr>
                    </thead>

                    <tbody>
                      {!userList ? (
                        <Fragment>
                          <tr>
                            <td colSpan="12">
                              <div className="d-flex justify-content-center py-4">
                                <Oval
                                  height={40}
                                  width={40}
                                  color="#4fa94d"
                                  wrapperStyle={{}}
                                  wrapperClass=""
                                  visible={true}
                                  ariaLabel="oval-loading"
                                  secondaryColor="#4fa94d"
                                  strokeWidth={5}
                                  strokeWidthSecondary={5}
                                />
                              </div>
                            </td>
                          </tr>
                        </Fragment>
                      ) : userList.status ? (
                        userList.list.map((data, i) => (
                          <UserDetails data={data} index={i} key={i} />
                        ))
                      ) : (
                        <>
                          <tr>
                            <td colSpan="12" className="text-center">
                              <div className="d-inline-block mx-auto  my-5">
                                <CardTitle className="mt-0">
                                  <MagnifyingGlass
                                    visible={true}
                                    height="80"
                                    width="80"
                                    ariaLabel="MagnifyingGlass-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="MagnifyingGlass-wrapper"
                                    glassColor="#c0efff"
                                    color="#e15b64"
                                  />
                                </CardTitle>
                                <CardText className="text-danger">
                                  {userList?.message}
                                </CardText>
                              </div>
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Fragment>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(AdminList)
